import { useEffect, useState } from "react";

const Delayed = ({ delayMiliseconds = 0, children }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delayMiliseconds);

    return () => clearTimeout(timeout);
  }, [show]);

  if (!show) return null;

  return children;
};

export default Delayed;
