import Link from "next/link";

import { useEffect, useState } from "react";
import { Tween } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";
import Delayed from "../Delayed";
import { BannerStyles } from "./BannerStyles";

const Banner = ({ bannerCopy = "" }) => {
  const [animationClass, setAnimationClass] = useState("");

  useEffect(() => {
    window.checkScrollColors();
    setTimeout(function () {
      setAnimationClass("animate1");
    }, 1000);
    setTimeout(function () {
      setAnimationClass("animate1 animate2");
    }, 2000);
  }, []);

  return (
    <BannerStyles>
      <div id="sticky-trigger" />
      <div id="dark-trigger" />
      <Controller>
        <div className="banner" id="banner">
          <Scene duration="100%" triggerElement="#sticky-trigger" triggerHook="onLeave" pin>
            <div className="bgvideo">
              <div className="fullscreen">
                <Delayed>
                  <iframe
                    loading="lazy"
                    src="https://player.vimeo.com/video/951160400?h=40a2dcfa04&amp;speed=0&amp;muted=1&amp;autoplay=1&amp;loop=1&amp;background=1&amp;app_id=122963"
                    width="640"
                    height="338"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen=""
                    title="alenka-far"
                    // data-ready="true"
                  />
                </Delayed>
              </div>
            </div>
          </Scene>
          <Scene duration="200%" triggerElement="#dark-trigger" triggerHook="onLeave">
            {(progress) => (
              <Tween
                to={{
                  opacity: 0.8,
                }}
                totalProgress={progress}
                paused
              >
                <div className="dark-overlay" />
              </Tween>
            )}
          </Scene>
          <div className={"logo-container " + animationClass}>
            <div className="animated-vertical" />
            <div className="animated-horizontal" />
            <img width="300" height="300" src="/long_lead_logo.svg" alt="Long Lead Logo" loading="lazy" />
          </div>
          <div id="about-trigger" />
          <div className="banner-content">
            <Scene
              reverse={false}
              triggerElement="#about-trigger"
              offset={300}
              triggerHook="onCenter"
              classToggle={[".banner-content", "show"]}
            >
              <div className="animate-text">
                <h1 className="intro-copy" dangerouslySetInnerHTML={{ __html: bannerCopy }} />
                <Link href="/about-us" legacyBehavior>
                  <a title="About Long Lead">
                    <span>ABOUT LONG LEAD</span>
                    <img width="43" height="18" src="/right-arrow.svg" alt="Blue arrow" loading="lazy" />
                  </a>
                </Link>
              </div>
            </Scene>
          </div>
        </div>
        <div id="end-trigger" />
        <Scene
          triggerElement="#end-trigger"
          duration="2000px"
          triggerHook="onLeave"
          classToggle={[".stories-menu-link", "active"]}
        >
          <div />
        </Scene>
      </Controller>
    </BannerStyles>
  );
};

export default Banner;
