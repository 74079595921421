import styled from "styled-components";

export const BannerStyles = styled.div`
  position: relative;
  background: #4a5254;
  overflow: hidden;

  .banner {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.5) 120px, rgba(0, 0, 0, 0) 50%);
      z-index: 1;
      pointer-events: none;

      @media only screen and (max-width: 767px) {
        background: rgba(0, 0, 0, 0.5);
      }
    }

    .logo-container {
      position: relative;
      height: 100vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 999999;
      overflow: hidden;
      pointer-events: none;

      img {
        display: block;
        width: 21vw;
        height: auto;
        margin: auto;

        @media only screen and (max-width: 767px) {
          width: 178px;
        }
      }

      .animated-vertical,
      .animated-horizontal {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
      }

      .animated-vertical:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background: #efedea;
        margin-bottom: -9vw;
        transition: 1.5s ease all;

        @media only screen and (max-width: 767px) {
          margin-bottom: -75px;
        }
      }

      .animated-vertical:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background: #efedea;
        margin-top: -9vw;
        transition: 1.5s ease all;

        @media only screen and (max-width: 767px) {
          margin-top: -75px;
        }
      }

      .animated-horizontal:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50%;
        height: 100%;
        background: #efedea;
        margin-right: -13vw;
        transition: 2s linear all;

        @media only screen and (max-width: 767px) {
          margin-right: -110px;
        }
      }

      .animated-horizontal:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background: #efedea;
        margin-left: -13vw;
        transition: 2s linear all;

        @media only screen and (max-width: 767px) {
          margin-left: -110px;
        }
      }

      &.animate1 .animated-vertical:after {
        bottom: -100%;
      }

      &.animate1 .animated-vertical:before {
        top: -100%;
      }

      &.animate2 .animated-horizontal:after {
        right: -100%;
      }

      &.animate2 .animated-horizontal:before {
        left: -100%;
      }
    }

    .banner-content {
      position: relative;
      height: 100vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      z-index: 2;

      .animate-text {
        position: relative;
        transition: 1s ease all;
        opacity: 0;
        bottom: -100px;
        text-align: center;
      }

      &.show .animate-text {
        opacity: 1;
        bottom: 0;
      }

      h1 {
        display: block;
        color: #fff;
        font-size: 70px;
        font-weight: 300;
        line-height: 70px;
        letter-spacing: -0.03em;
        margin: 0;
        max-width: 957px;

        @media only screen and (max-width: 1439px) {
          max-width: 737px;
          font-size: 54px;
          line-height: 54px;
        }

        @media only screen and (max-width: 1079px) {
          max-width: 629px;
          font-size: 48px;
          line-height: 48px;
        }

        @media only screen and (max-width: 767px) {
          max-width: 319px;
          font-size: 30px;
          line-height: 30px;
        }
      }

      a {
        display: inline-flex;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        margin-top: 40px;
        align-items: center;

        @media only screen and (max-width: 767px) {
          margin-top: 25px;
        }

        img {
          position: relative;
          left: 0;
          display: inline-block;
          margin-left: 9px;
          transition: 0.25s ease all;
        }
        &:hover {
          img {
            left: 5px;
          }
        }
      }
    }

    .bgvideo {
      pointer-events: none;
      overflow: hidden;
      position: relative;
      height: 100vh;
      width: 100%;
      left: 0 !important;

      .fullscreen {
        position: relative;
        height: 100%;
        width: 100%;
        @media (min-aspect-ratio: 1280/675) {
          height: 300%;
          top: -100%;
        }
        @media (max-aspect-ratio: 1280/675) {
          width: 400%;
          left: -150%;
        }
      }
      iframe,
      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }

    .scrollmagic-pin-spacer {
      position: absolute !important;
    }

    .dark-overlay {
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0;
    }
  }
`;
